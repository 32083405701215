import {
  Box,
  FormControl
} from '@mui/material';
import MultipleAutocompleteWithThrottle from '../multiple-autocomplete-with-throttle/multiple-autocomplete-with-throttle';
import { FilterBarProps, FilterMethods } from './filter-bar.interface';
import { DefaultFilter } from './filter-types';



function FilterBar<T extends FilterMethods>({
  handleFetchFilters,
  filterQueryAction,
  currentlySelectedFilters,
  helpWidget,
}: FilterBarProps<T>) {
  return (
    <Box
      component="fieldset"
      border={'none'}
      borderTop={'1px solid rgb(0,0,0,0.1)'}
    >
      <legend>
        <b>Filter Controls</b>
      </legend>
      <FormControl fullWidth>
        <MultipleAutocompleteWithThrottle<T>
          label={'Filters'}
          fetch={handleFetchFilters}
          value={currentlySelectedFilters}
          getOptionLabel={(option: T) => { return option.to_string(); }}
          isOptionEqualToValue={(option: T, choice: T) => {
            return option.get_key() === choice.get_key() && option.get_value() === choice.get_value();
          }}
          filterHelp={helpWidget}
          onChange={(e, value) => {
            if (e === null) return;
            if (value == null) {
              filterQueryAction([]);
            } else {
              filterQueryAction(value);
            }
          }}
        />
      </FormControl>
    </Box>
  );
}

export default FilterBar;