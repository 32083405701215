import { lebaneseGovernorates, regions } from "../../../data/regions";
import { distance } from 'fastest-levenshtein';


export function getClosestDistrict(apiDistrict: string) {
    const lowerCasedAPIDistrict = apiDistrict.toLowerCase()
    let closestMatch = lebaneseGovernorates[0]
    let minDistance = Math.min(
        distance(lowerCasedAPIDistrict, closestMatch.AR.toLowerCase()),
        distance(lowerCasedAPIDistrict, closestMatch.EN.toLowerCase()),
    )


    lebaneseGovernorates.forEach(district => {
        const dist = Math.min(
            distance(lowerCasedAPIDistrict, district.AR.toLowerCase()),
            distance(lowerCasedAPIDistrict, district.EN.toLowerCase()),
        )
        if (dist < minDistance) {
            closestMatch = district;
            minDistance = dist;
        }
    });

    console.log("Min distance: ", minDistance)
    return closestMatch.EN;
}
