import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import MuiPhoneNumber from 'mui-phone-number';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { emptyCustomer } from '../../../data/customers';
import { RegionType, emptyRegion, getGovernorateForDistrict, regions } from '../../../data/regions';
import { CustomerType } from '../../../interfaces/common/customer';
import ConfirmAction from '../confirm-action/confirm-action';
import { ensureZeroAfterCountryCode } from '../../../utils/phone_numbers';
import AddressInputWithAutocomplete from '../adress-input-with-autocomplete/address-input-with-autocomplete';

export type CustomerEffect = (c: CustomerType) => void;

interface CreateCustomerFormProps {
  defaultCustomerId?: string;
  baseEndpoint: string;
  orgId?: string;
  successCallback?: CustomerEffect;
}

function CreateCustomerForm({
  defaultCustomerId,
  baseEndpoint,
  orgId,
  successCallback,
}: CreateCustomerFormProps) {
  const [customerData, setCustomerData] = useState<CustomerType>();
  const [regionSearchInput, setRegionSearchInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [response, setResponse] = useState<AxiosResponse>();
  const [message, setMessage] = useState<string>('');

  const [confirmDeleteCustomer, setConfirmDeleteCustomer] =
    useState<boolean>(false);

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  console.log(customerData);
  useEffect(() => {
    const getAndSetCustomer = async () => {
      if (defaultCustomerId !== undefined) {
        setIsLoading(true);
        try {
          let endpoint = `${baseEndpoint}/customer/${defaultCustomerId}/`;
          if (orgId) {
            endpoint += `?org=${encodeURIComponent(orgId)}`;
          }

          const response = await axios.get<CustomerType>(endpoint, config);
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK') {
            setCustomerData(response.data);
            setRegionSearchInput(response.data.district);
          }
        } catch (e) {
          setCustomerData(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load customer  data: ' + e.message);
          } else {
            setMessage('Failed to load customer  data');
          }
          console.log(e);
        }
        setIsLoading(false);
      } else {
        setCustomerData(emptyCustomer);
      }
    };
    getAndSetCustomer();
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (customerData) {
      setIsLoading(true);
      setResponse(undefined);
      setLoadingMessage('Saving Customer...');
      const requestData = {
        name: customerData.name,
        address: customerData.address,
        phone_number: ensureZeroAfterCountryCode(customerData.phone_number).replaceAll(' ', ''),
        district: customerData.district,
        governorate: customerData.governorate,
      };

      try {
        let response;
        console.log(requestData);
        if (defaultCustomerId === undefined) {
          // create new
          console.log('creating new customer..');
          let endpoint = `${baseEndpoint}/customers/`;
          if (orgId) {
            endpoint += `?org=${encodeURIComponent(orgId)}`;
          }
          response = await axios.post(endpoint, requestData, config);
        } else {
          // edit existing
          let endpoint = `${baseEndpoint}/customer/${customerData.id}/`;
          if (orgId) {
            endpoint += `?org=${encodeURIComponent(orgId)}`;
          }
          response = await axios.patch(endpoint, requestData, config);
        }
        setResponse(response);
        console.log(response);

        if (response.status >= 200 && response.status < 300) {
          setMessage('Customer saved successfully');
          successCallback && successCallback(response.data);
        }
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response)
          setMessage('Failed to save customer: ' + e.response?.statusText);
        } else {
          setMessage('Failed to save customer');
        }
        console.log(e);
      }
      setIsLoading(false);
      setLoadingMessage('');
    }
  };

  const deleteCustomer = async () => {
    if (customerData) {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${baseEndpoint}/customer/${customerData.id}/ `,
          config
        );
        setResponse(response);
        if (response.status === 204) {
          setCustomerData(undefined);
          setMessage('Customer deleted successfully');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setMessage('Failed to delete customer: ' + e.message);
        } else {
          setMessage('Failed to delete customer');
        }
        console.log(e);
      }
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Typography
        variant="h3"
        component="h3"
        sx={{ textAlign: 'center', mt: 3, mb: 3 }}
      >
        {defaultCustomerId === undefined ? 'Create Customer' : 'Edit Customer'}
      </Typography>

      {isLoading ? (
        <>
          {loadingMessage} <br /> <LinearProgress />
        </>
      ) : (
        <>
          {message != '' && (
            <>
              <Alert
                severity={
                  response && response.status >= 200 && response.status < 300
                    ? 'success'
                    : 'error'
                }
              >
                <>
                  {message}

                  {response &&
                    response.status >= 300 &&
                    Object.keys(response.data).map((key) => (
                      <>
                        <ul>
                          {
                            typeof response.data[key] === "string" ?
                              <li>{response.data[key]}</li> :
                              response.data[key].map((validationError: string) => (
                                <li key={key}>
                                  {key}: {validationError}
                                </li>
                              ))}
                        </ul>
                      </>
                    ))}
                </>
              </Alert>
              <br /> </>
          )}

          {customerData && (
            <>
              <br></br>
              <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                  <TextField
                    label="Customer Name"
                    type="text"
                    variant="outlined"
                    required
                    value={customerData.name}
                    onChange={(e) =>
                      setCustomerData({ ...customerData, name: e.target.value })
                    }
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <MuiPhoneNumber
                    label="Customer Phone Number"
                    type="tel"
                    defaultCountry={'lb'}
                    variant="outlined"
                    required
                    value={customerData.phone_number}
                    onChange={(newPhone: any) => {
                      if (typeof newPhone === 'string') {
                        setCustomerData({
                          ...customerData,
                          phone_number: newPhone,
                        });
                      } else
                        setCustomerData({
                          ...customerData,
                          phone_number: newPhone.target.value,
                        });
                    }}
                  />
                </FormControl>

                <br />
                <br />

                <FormControl fullWidth>
                  <AddressInputWithAutocomplete
                    initialAddress={customerData.address}
                    callback={(address, district, governorate) => {
                      setCustomerData(
                        (prev) => {
                          return {
                            ...(prev ?? emptyCustomer),
                            address: address,
                            district: district,
                            governorate: governorate,
                          }
                        })
                      setRegionSearchInput(district)
                    }}
                  />
                </FormControl>

                <br />
                <br />

                <Autocomplete
                  disablePortal
                  id="district"
                  value={
                    customerData.governorate === ''
                      ? null
                      : ({
                        governorate: customerData.governorate,
                        district: customerData.district,
                      } as RegionType)
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.district === value.district
                  }
                  onChange={(_, newValue: RegionType | null) => {
                    if (newValue === null) newValue = emptyRegion;
                    const newCustomerData = {
                      ...customerData,
                      district: newValue.district,
                      governorate: newValue.governorate,
                    };
                    setCustomerData(newCustomerData);
                  }}
                  inputValue={regionSearchInput}
                  onInputChange={(event, newInputValue) => {
                    if (event != null) setRegionSearchInput(newInputValue);
                  }}
                  options={regions.sort((a, b) =>
                    b.governorate.localeCompare(a.governorate)
                  )}
                  groupBy={(option) => option.governorate}
                  getOptionLabel={(option) => option.district}
                  renderInput={(params) => (
                    <TextField {...params} required label="District" />
                  )}
                />

                <br />
                <br />

                <div style={{ display: 'flex', gap: 7 }}>
                  <Button type="submit" variant="contained">
                    Save Customer
                  </Button>

                  {customerData.id !== '' && (
                    <Button
                      onClick={() => {
                        setConfirmDeleteCustomer(true);
                      }}
                      variant="outlined"
                      color="error"
                    >
                      Delete Customer
                    </Button>
                  )}
                </div>
              </form>{' '}
            </>
          )}
        </>
      )}

      <ConfirmAction
        message={'Are you sure you want to delete this Customer?'}
        action={deleteCustomer}
        isOpen={confirmDeleteCustomer}
        handleClose={() => setConfirmDeleteCustomer(false)}
        isSevereAction={true}
        confirmSevereActionTarget="delete customer"
      >
        <div>
          {' '}
          Attention: This action will delete all orders registered for this
          customer!
        </div>
      </ConfirmAction>
    </Box>
  );
}

export default CreateCustomerForm;
