import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import React, { useContext, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import {
  PDFDownloadLink,
  PDFViewer
} from '@react-pdf/renderer';
import axios, { AxiosResponse, isAxiosError } from 'axios';
import { isDesktop, isMobile } from 'react-device-detect';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { InvoiceMetaType } from '../../../interfaces/common/invoice';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { IInvoice } from '../invoice-builder/Invoice';
import {
  defaultDriverReportFieldsConfig,
  defaultMerchantReportFieldsConfig,
} from '../report-builder/fields-config';
import { ReportDocument, ReportProps } from '../report-builder/report';
import {
  compute_total_commission_lbp,
  compute_total_commission_usd,
  compute_total_delivery_fee_lbp,
  compute_total_delivery_fee_usd,
  compute_total_price_lbp,
  compute_total_price_usd,
} from '../report-builder/utils';

interface InvoicesListingTableProps {
  rows: InvoiceMetaType[];
  setRows: React.Dispatch<React.SetStateAction<InvoiceMetaType[]>>;
  isLoading: boolean;
  allowEditNote?: boolean;
  selectedOrg?: string;
  baseEndpoint: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setResponse: React.Dispatch<React.SetStateAction<AxiosResponse<any, any> | undefined>>;
}

function InvoicesListingTable({
  rows,
  setRows,
  isLoading,
  allowEditNote,
  selectedOrg,
  baseEndpoint,
  setMessage,
  setResponse,
}: InvoicesListingTableProps) {
  const [lockedInvoiceMeta, setLockedInvoiceMeta] =
    useState<InvoiceMetaType | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [pdfData, setPdfData] = useState<IInvoice | null>(null);

  const { user } = useContext(AuthContext);
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEditNote = async (invoice_id: string) => {
    const note = prompt('Enter new note');
    if (note === null) {
      return;
    }

    const endpoint = `${baseEndpoint}/invoice-meta/${encodeURIComponent(invoice_id)}/`;

    try {
      const response = await axios.put(endpoint, { notes: note }, config);
      const clonedRows: InvoiceMetaType[] = rows.map((invoice) =>
        invoice.invoice_id === invoice_id
          ? { ...invoice, notes: note }
          : invoice
      );
      setRows(clonedRows);
      setResponse(response);
    } catch (e) {
      if (isAxiosError(e)) {
        setResponse(e.response)
        setMessage("Failed to modify invoice note: " + e.response?.statusText)
      } else {
        console.log("Failed to modify invoice note");
      }
      console.log(e);
    }
  };

  const PDFDialog = () => {
    console.log('From PDF Dialog: ', pdfData);
    if (pdfData === null) return;
    if (!pdfData.driver && !pdfData.merchant) {
      console.error(
        'Invoice recepient type is not known. It should be either driver or merchant'
      );
      return;
    }
    const reportDocProps: ReportProps = {
      tables: [
        {
          orders: pdfData.orders,
          fields_config: pdfData.driver
            ? defaultDriverReportFieldsConfig
            : defaultMerchantReportFieldsConfig,
          header_bg: '#eee',
          title: 'Orders',
          table_footer_rows: pdfData.merchant
            ? [
              {
                label: 'total orders',
                compute_value: (orders: OrderType[]) =>
                  orders.length.toString(),
              },
              {
                label: 'price',
                compute_value: (orders) =>
                  `${compute_total_price_usd(orders)} $ + ${compute_total_price_lbp(orders)} L.L`,
              },
              {
                label: 'delivery fee',
                compute_value: (orders) =>
                  `${compute_total_delivery_fee_usd(orders)} $ + ${compute_total_delivery_fee_lbp(orders)} L.L`,
              },
            ]
            : [
              {
                label: 'total orders',
                compute_value: (orders: OrderType[]) =>
                  orders.length.toString(),
              },
              {
                label: 'price to collect',
                compute_value: (orders) =>
                  `${compute_total_price_usd(orders) + compute_total_delivery_fee_usd(orders) - compute_total_commission_usd(orders)} $ + ${compute_total_price_lbp(orders) + compute_total_delivery_fee_lbp(orders) - compute_total_commission_lbp(orders)} L.L`,
              },
              {
                label: 'driver commission',
                compute_value: (orders) =>
                  `${compute_total_commission_usd(orders)} $ + ${compute_total_commission_lbp(orders)} L.L`,
              },
            ],
        },
      ],
      subtitles: [
        pdfData.driver
          ? `Driver: ${pdfData.driver.name} / ${pdfData.driver.phone_number}`
          : `Merchant: ${pdfData.merchant?.name} / ${pdfData.merchant?.phone_number}`,
      ],
      organisation_id: pdfData.organisation_id,
      date_from: new Date(parseInt(pdfData.timestamp) * 1000).toLocaleDateString(),
      date_to: new Date(parseInt(pdfData.timestamp) * 1000).toLocaleDateString(),
      title: `${pdfData.type} Invoice #${pdfData.invoice_id}`,
    };

    return (
      <DialogContent
        style={
          isDesktop
            ? { width: '100%', height: '80vh', zIndex: 9999999999999 }
            : { zIndex: 9999999999999 }
        }
      >
        {isMobile ? (
          <>
            {pdfData && (
              <PDFDownloadLink
                document={<ReportDocument {...reportDocProps} />}
                fileName={pdfData.invoice_id}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <LinearProgress />
                  ) : (
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Button variant="outlined">
                        Save to device{' '}
                        <DownloadIcon color={'primary'} fontSize="large" />
                      </Button>
                    </div>
                  )
                }
              </PDFDownloadLink>
            )}
          </>
        ) : (
          <>
            {pdfData && (
              <PDFViewer style={{ width: '100%', height: '100%' }}>
                <ReportDocument {...reportDocProps} />
              </PDFViewer>
            )}
          </>
        )}
      </DialogContent>
    );
  };

  const fetchInvoiceContent = async (invoicemeta: InvoiceMetaType) => {
    setLockedInvoiceMeta(invoicemeta);

    let endpoint = `${baseEndpoint}/invoice/${encodeURIComponent(invoicemeta.invoice_id)}/`;

    if (selectedOrg) {
      endpoint += `?org=${encodeURIComponent(selectedOrg)}`;
    }
    try {
      const response = await axios.get(endpoint, config);
      setResponse(response)
      const data: IInvoice = response.data;
      data.orders.forEach((order) => (order.order_id = order.id));
      setPdfData(data);
      setOpenDialog(true);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setResponse(e.response)
        setMessage("Failed to download invoice content: " + e.response?.statusText)
      } else {
        setMessage("Failed to download invoice content")
      }
    }
    setLockedInvoiceMeta(null);
  };

  return (
    <>
      <TableContainer style={{ maxHeight: '80%' }}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" key={'tableCellFile'}>
                  <b>Invoice Id</b>
                </TableCell>
                <TableCell align="center" key={'tableCellType'}>
                  <b>Type</b>
                </TableCell>
                <TableCell align="center" key={'tableCellDate'}>
                  <b>Date</b>
                </TableCell>
                <TableCell align="center" key={'tableCellNotes'}>
                  <b>Notes</b>
                </TableCell>
                <TableCell align="center" key={'tableCellDownload'}>
                  <b>Download</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((invoice, index) => (
                <TableRow key={invoice.id}>
                  <TableCell
                    align="center"
                    key={'tableCellId'}
                    style={{ cursor: 'pointer' }}
                  >
                    <b>{invoice.invoice_id}</b>
                  </TableCell>
                  <TableCell align="center" key={'tableCellType'}>
                    {invoice.invoice_type === 'delivery' ? (
                      <Chip
                        label="Delivery"
                        color={'success'}
                        variant="outlined"
                      />
                    ) : (
                      <Chip
                        label="Cancellation"
                        color="error"
                        variant="outlined"
                      />
                    )}
                  </TableCell>
                  <TableCell align="center" key={'tableCellDate'}>
                    {new Date(invoice.timestamp).toLocaleString()}
                  </TableCell>
                  <TableCell align="center" key={'tableCellNotes'}>
                    {invoice.notes}

                    {allowEditNote && (
                      <IconButton
                        onClick={() => {
                          handleEditNote(invoice.invoice_id);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                  </TableCell>

                  <TableCell align="center" key={'tableCellDownload'}>
                    <IconButton
                      onClick={() => fetchInvoiceContent(invoice)}
                      disabled={lockedInvoiceMeta !== null}
                    >
                      {lockedInvoiceMeta?.invoice_id === invoice.invoice_id ? (
                        <CircularProgress />
                      ) : (
                        <PreviewIcon fontSize="large" />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        style={{ zIndex: 9999999999999 }}
      >
        <div
          style={{
            textAlign: 'right',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <DialogTitle>Invoice Ready 🎉</DialogTitle>
          <IconButton onClick={() => handleCloseDialog()}>
            <ClearIcon color="primary" fontSize="large" />
          </IconButton>
        </div>

        {PDFDialog()}
      </Dialog>
    </>
  );
}

export default InvoicesListingTable;
