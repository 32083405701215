export interface RegionType {
  governorate: string;
  district: string;
}

export const emptyRegion: RegionType = {
  governorate: '',
  district: '',
};

export const regions: RegionType[] = [
  { governorate: 'Beqaa', district: 'Zahleh' },
  { governorate: 'Nabatieh', district: 'Hasbaya' },
  { governorate: 'Beqaa', district: 'Baalbek' },
  { governorate: 'South', district: 'Jezzine' },
  { governorate: 'Mount Lebanon', district: 'Baabda' },
  { governorate: 'Mount Lebanon', district: 'Jbeil' },
  { governorate: 'North', district: 'Zgharta' },
  { governorate: 'Nabatieh', district: 'Bint Jbeil' },
  { governorate: 'Beqaa', district: 'Hermel' },
  { governorate: 'Nabatieh', district: 'Marjeyoun' },
  { governorate: 'North', district: 'Bsharri' },
  { governorate: 'Mount Lebanon', district: 'Aley' },
  { governorate: 'Beqaa', district: 'Rashaya' },
  { governorate: 'North', district: 'Miniyeh-Danniyeh' },
  { governorate: 'North', district: 'Tripoli' },
  { governorate: 'South', district: 'Tyre' },
  { governorate: 'Nabatieh', district: 'Nabatieh' },
  { governorate: 'Mount Lebanon', district: 'Chouf' },
  { governorate: 'Mount Lebanon', district: 'Maatn' },
  { governorate: 'Beqaa', district: 'Western Beqaa' },
  { governorate: 'North', district: 'Akkar' },
  { governorate: 'South', district: 'Sidon' },
  { governorate: 'North', district: 'Batroun' },
  { governorate: 'Mount Lebanon', district: 'Keserwan' },
  { governorate: 'North', district: 'Koura' },
  { governorate: 'Beirut', district: 'Beirut' },
];

export const lebaneseGovernorates: { EN: string, AR: string }[] = [
  { EN: "Zahleh", AR: "زحلة" },
  { EN: "Hasbaya", AR: "حاصبيا" },
  { EN: "Baalbek", AR: "بعلبك" },
  { EN: "Jezzine", AR: "جزين" },
  { EN: "Baabda", AR: "بعبدا" },
  { EN: "Jbeil", AR: "جبيل" },
  { EN: "Zgharta", AR: "زغرتا" },
  { EN: "Bint Jbeil", AR: "بنت جبيل" },
  { EN: "Hermel", AR: "الهرمل" },
  { EN: "Marjeyoun", AR: "مرجعيون" },
  { EN: "Bsharri", AR: "بشري" },
  { EN: "Aley", AR: "عاليه" },
  { EN: "Rashaya", AR: "راشيا" },
  { EN: "Miniyeh-Danniyeh", AR: "المنية-الضنية" },
  { EN: "Tripoli", AR: "طرابلس" },
  { EN: "Tyre", AR: "صور" },
  { EN: "Nabatieh", AR: "النبطية" },
  { EN: "Chouf", AR: "الشوف" },
  { EN: "Maatn", AR: "المتن" },
  { EN: "Western Beqaa", AR: "البقاع الغربي" },
  { EN: "Akkar", AR: "عكار" },
  { EN: "Sidon", AR: "صيدا" },
  { EN: "Batroun", AR: "البترون" },
  { EN: "Keserwan", AR: "كسروان" },
  { EN: "Koura", AR: "الكورة" },
  { EN: "Beirut", AR: "بيروت" }
];

export const getGovernorateForDistrict = (district: string): string => {
  const matched_regions = regions.filter((item) => item.district === district);
  if (matched_regions.length !== 1) {
    return '';
  }
  return matched_regions[0].governorate;
};
