import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { enableMapSet } from 'immer';
import { getClosestDistrict } from "./utils";
import { getGovernorateForDistrict } from "../../../data/regions";
import axios from "axios";
import { text } from "stream/consumers";

interface AddressWithAutocompleteProps {
    inputRef: React.RefObject<HTMLInputElement>;
    callback: (address: string, district: string, governorate: string) => void;
}
const AddressWithAutocomplete = (
    {
        inputRef,
        callback,
    }:
        AddressWithAutocompleteProps
) => {
    useEffect(() => {
        if (inputRef.current && window.google) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
                componentRestrictions: {
                    country: 'lb',

                }
            });

            autocomplete.addListener('place_changed', () => {
                const selectedPlace = autocomplete.getPlace();
                console.log(selectedPlace)
                const address: string = inputRef.current?.value ?? ""
                let district = ""
                let governorate = ""
                const addressComponents = selectedPlace.address_components
                if (addressComponents && addressComponents.filter(item => item.types.includes("administrative_area_level_2"))) {
                    const districtComponents = addressComponents.filter(item => item.types.includes("administrative_area_level_2"))
                    district = getClosestDistrict(districtComponents[0].short_name)
                    governorate = getGovernorateForDistrict(district)
                }

                callback(address, district, governorate);
            });
        }
    }, []);

    return <></>
};

interface AddressInputWithAutocompleteProps {
    callback: (address: string, district: string, governorate: string) => void;
    initialAddress: string;
}
export default function AddressInputWithAutocomplete({ callback, initialAddress }: AddressInputWithAutocompleteProps) {
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (!inputRef.current) return
        const textField: HTMLInputElement = inputRef.current
        textField.value = initialAddress
    }, [initialAddress])

    return <>
        <TextField
            inputRef={inputRef}
            fullWidth
            defaultValue={initialAddress}
            type="string"
            variant="outlined"
            placeholder="Address *"
            required
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }}
            onBlur={(e) => { console.log("blurred: ", e.target.value) }}
        />

        < AddressWithAutocomplete inputRef={inputRef} callback={callback} />
    </>

}

