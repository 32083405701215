import { MerchantType } from '../interfaces/delivery-company/merchants';

export const emptyMerchant: MerchantType = {
  id: '',
  name: '',
  phone_number: '',
  address: '',
  has_usable_password: false,
  notifications_enabled: false,
};
