import {
  Alert,
  Box,
  LinearProgress,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import { API_ENDPOINT } from '../../configurations/global.config';
import { GenericResponseType } from '../../interfaces/common/common';
import { OrderType } from '../../interfaces/delivery-company/orders';
import { generateStateTrace } from './delivery-tracker.utils';

const DeliveryTracker = () => {
  const { hash } = useParams();

  const [order, setOrder] = useState<OrderType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<GenericResponseType>({
    message: '',
  });

  useEffect(() => {
    const getOrder = async () => {
      setLoading(true);

      if (hash === undefined) return;

      const endpoint = `${API_ENDPOINT}/api/order-tracker/${encodeURI(hash)}`;
      console.log(endpoint);
      let response;

      try {
        response = await axios.get<OrderType>(endpoint);
        console.log(response);
        if (response.statusText === 'OK') {
          setOrder(response.data);
        } else {
          setResponse({ statusCode: 404, message: 'Order not found' });
        }
      } catch (e) {
        setResponse({ statusCode: 500, message: 'Failed to fetch order data' });
        console.log(e);
      }
      setLoading(false);
    };

    getOrder();
  }, [hash]);

  const maxLineLength = 20;
  const splitText = (text: string): string[] => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    for (const word of words) {
      if (currentLine.length + word.length <= maxLineLength) {
        currentLine += (currentLine.length > 0 ? ' ' : '') + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine.length > 0) {
      lines.push(currentLine);
    }

    return lines;
  };

  return (
    <div
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'rgba(128, 95, 168,0.06)',
        minHeight: '100vh',
        minWidth: '100vw',
        overflow: 'auto',
        padding: '5px',
      }}
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          {order === undefined ? (
            <Alert severity="error"> {response.message} </Alert>
          ) : (
            <>
              <Box
                component="img"
                sx={{
                  maxHeight: {
                    xs: Math.min(233, window.outerHeight),
                    md: Math.min(167, window.outerHeight),
                  },
                  maxWidth: {
                    xs: Math.min(350, window.outerWidth),
                    md: Math.min(250, window.outerWidth),
                  },
                }}
                src="/main-logo.png"
              />
              <br />
              <br />

              <div
                style={{ display: 'flex', gap: 20, flexDirection: 'column' }}
              >
                <div>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ mt: 1, mb: 1 }}
                    textAlign={'center'}
                  >
                    Order Details
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    sx={{
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid md={4} xs={6}>
                      <b>Agency</b> <br />
                      {order.organisation?.organisation_id}
                      <br /> <br />
                    </Grid>

                    <Grid md={4} xs={6}>
                      <b>Customer</b>
                      <br />
                      {order.customer?.name}
                      <br /> <br />
                    </Grid>

                    <Grid md={4} xs={6}>
                      <b>Merchant</b> <br />
                      {order.merchant.name}
                      <br />
                      <br />
                    </Grid>
                    <Grid md={4} xs={6}>
                      <b>Merchant Phone</b> <br />
                      {order.merchant.phone_number}
                      <br />
                      <br />
                    </Grid>
                    <Grid md={4} xs={6}>
                      <b>Description</b> <br />
                      {splitText(order.description).map((line) => (
                        <>
                          {' '}
                          {line} <br />
                        </>
                      ))}
                      <br /> <br />
                    </Grid>
                    <Grid md={4} xs={6}>
                      <b>#Packages</b> <br />
                      {order.number_of_packages}
                      <br />
                      <br />
                    </Grid>
                  </Grid>
                </div>

                <div>
                  <Typography
                    variant="h5"
                    component="h5"
                    sx={{ mt: 1, mb: 1 }}
                    textAlign={'center'}
                  >
                    Delivery History
                  </Typography>
                  {order.statestamps !== undefined && (
                    <div
                      style={{
                        maxWidth: '100vw',
                        overflow: 'scroll',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Stepper
                        activeStep={order.statestamps.length}
                        orientation={isMobile ? 'vertical' : 'horizontal'}
                        sx={{ overflow: 'scroll' }}
                      >
                        {generateStateTrace(order.statestamps).map((step) => (
                          <Step key={step.label}>
                            <StepLabel
                              StepIconComponent={() => (
                                <StepIcon error={true} icon={step.icon} />
                              )}
                            >
                              <>
                                <b>{step.label}</b>
                                <br />
                                {step.timestamp && (
                                  <>
                                    {new Date(step.timestamp)
                                      .toLocaleString()
                                      .split(',')
                                      .map((item) => (
                                        <>
                                          {item}
                                          <br />
                                        </>
                                      ))}
                                  </>
                                )}
                              </>
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DeliveryTracker;
