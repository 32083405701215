import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputAdornment,
  LinearProgress,
  TextField
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { LabelKeyWithValues } from '../../../interfaces/delivery-company/labels';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { AuthContext } from '../../../contexts/user-context/user-context';
import axios from 'axios';
import { UserType } from '../../../enums/users';

export interface LabelValueWithKey {
  id: string;
  value: string;
  key: string;
}

export interface LabelsInputProps {
  currentlySelectedLabels: LabelValueWithKey[];
  setCurrentlySelectedLabels: (labels: LabelValueWithKey[]) => void;
}

export const ExtractOptions = (labelKeys: LabelKeyWithValues[]) => {
  const options: LabelValueWithKey[] = []
  labelKeys.forEach(labelKey => {
    labelKey.values.forEach(labelValue =>
      options.push({
        ...labelValue,
        key: labelKey.key,
      })
    )
  }
  )

  console.log("options: ", options)
  return options
}
function LabelsInput({
  currentlySelectedLabels,
  setCurrentlySelectedLabels,
}: LabelsInputProps) {
  const [labelInput, setLabelInput] = React.useState<string>("")
  const [availableLabels, setAvailableLabels] = React.useState<LabelValueWithKey[]>([])
  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState("")
  console.log(availableLabels)

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const isMerchant = user?.type === UserType.MERCHANT

  useEffect(() => {
    const fetchAvailableLabels = async () => {
      if (!errorMessage && availableLabels.length === 0 && labelInput.length > 0) {
        setLoading(true);
        setErrorMessage("");
        const endpoint = `${API_ENDPOINT}/api/${isMerchant ? "merchant" : "org"}/labelkeys`
        let pageNumber = 1
        let hasNext = true
        while (hasNext) {
          const currentEndpoint = `${endpoint}?page=${pageNumber}&page_size=1000`
          try {
            const response = await axios.get<{
              count: number;
              next?: string;
              results: LabelKeyWithValues[];
            }>(currentEndpoint, config);
            console.log(response);
            console.log(response.data.results)
            setAvailableLabels([...availableLabels, ...ExtractOptions(response.data.results)])
            if (!response.data.next) {
              hasNext = false
            }
          }
          catch (e) {
            console.log(e)
            if (axios.isAxiosError(e)) {
              setErrorMessage('Failed to load labels: ' + e.message);
            } else {
              setErrorMessage('Failed to load labels');
            }
            console.log(e);
            setLoading(false);
            break
          }

          pageNumber++
        }

        setLoading(false);
      }
    }
    fetchAvailableLabels()
  }, [labelInput])



  const getNoOptionsText = () => {
    if (errorMessage) return "No Options"
    if (loading) return "Fetching labels..."
    if (!labelInput) return "Start typing..."
    return "No Options"
  }


  return (
    <>
      <Autocomplete
        disablePortal
        multiple
        value={currentlySelectedLabels}
        onChange={(event: React.SyntheticEvent<Element, Event>, newValue) => {
          setCurrentlySelectedLabels(newValue)
          setLabelInput("")
        }}
        inputValue={labelInput}
        onInputChange={(
          event: any,
          newInputValue: string,
          reason
        ) => {
          if (reason === 'input') setLabelInput(newInputValue);
        }}
        id="controllable-states-demo"
        options={availableLabels}
        noOptionsText={getNoOptionsText()}
        getOptionLabel={(option) =>
          `${option.key}: ${option.value}`
        }
        isOptionEqualToValue={(option, value) =>
          option.id === value.id
        }
        renderOption={(params, option) => (
          <li {...params} key={option.id} >
            {`${option.key}: ${option.value}`}
          </li>
        )}

        renderInput={(params) => <TextField
          {...params}
          label="Labels"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {loading && <CircularProgress />}
              </InputAdornment>
            )
          }}
        />
        }
      />
      {
        errorMessage && <FormHelperText>
          <p style={{ color: "red" }}>{errorMessage}</p>
        </FormHelperText>
      }
    </>

  );
}

export default LabelsInput;
