import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import axios, { AxiosResponse } from 'axios';
import React, { useContext, useEffect } from 'react';
import Barcode from 'react-barcode';
import { API_ENDPOINT } from '../../configurations/global.config';
import { AuthContext } from '../../contexts/user-context/user-context';
import { OrderType } from '../../interfaces/delivery-company/orders';
import CreateEntityDrawer from '../common/create-entity-drawer/create-entity-drawer';
import CreateOrderForm from '../delivery-company-components/orders/create-order-form/create-order-form';
import { emptyOrder } from '../delivery-company-components/orders/create-order-form/create-order-form.utils';
import OrderInfo from './order-lookup.order-info';

interface OrderLookupDialogProps {
  orderId: string;
  onCloseDialog: any;
}

function OrderLookupDialog({ orderId, onCloseDialog }: OrderLookupDialogProps) {
  const [order, setOrder] = React.useState<OrderType>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [response, setResponse] = React.useState<AxiosResponse>();
  const [message, setMessage] = React.useState<string>('');

  const { user } = useContext(AuthContext);

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  console.log('now response = ', response);
  console.log('now message = ', message);

  useEffect(() => {
    const fetchAndSetOrder = async () => {
      setIsLoading(true);
      try {
        const endpoint = `${API_ENDPOINT}/api/org/orders/?order_id__equals=${encodeURIComponent(orderId)}`;
        console.log(endpoint);
        const response = await axios.get<{
          count: number;
          results: OrderType[];
        }>(endpoint, config);
        console.log(response);
        setResponse(response);
        if (response.statusText == 'OK' && response.data.count === 1) {
          setOrder(response.data.results[0]);
          setMessage('Order Found!');
        } else {
          setResponse({ ...response, statusText: 'NOT FOUND' });
          setMessage('Order Not Found');
        }
      } catch (e) {
        setOrder(undefined);
        if (axios.isAxiosError(e)) {
          setMessage('Failed to load order data: ' + e.message);
        } else {
          setMessage('Failed to load order data');
        }
        console.log(e);
      }
      setIsLoading(false);
    };
    fetchAndSetOrder();
  }, [orderId]);

  const resetState = () => {
    setIsLoading(false);
    setOrder(undefined);
    setResponse(undefined);
  };

  return (
    <div>
      <Dialog fullWidth open={true} >
        <DialogTitle>
          <div
            style={{
              textAlign: 'right',
              width: '100%',
            }}
          >
            <IconButton
              onClick={() => {
                resetState();
                onCloseDialog();
              }}
            >
              <ClearIcon color="primary" fontSize="large" />
            </IconButton>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {orderId !== '' && <Barcode width={3} value={orderId} />}
          </div>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <>
              {message !== '' && (
                <Alert
                  severity={response?.statusText === 'OK' ? 'success' : 'error'}
                >
                  {message}
                </Alert>
              )}

              {response?.statusText === 'NOT FOUND' && (
                <>
                  <br />

                  <div>
                    <CreateEntityDrawer
                      buttonFullWidth={true}
                      onClose={() => {
                        resetState();
                        onCloseDialog();
                      }}
                    >
                      <CreateOrderForm
                        defaultOrder={{ ...emptyOrder, order_id: orderId.trim() }}
                      />
                    </CreateEntityDrawer>
                  </div>
                </>
              )}
              {order && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <OrderInfo order={order} />
                </div>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OrderLookupDialog;
