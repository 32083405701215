import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import JsBarcode from 'jsbarcode';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';


const generateBarcode = (orderId: string) => {
    const canvas = document.createElement('canvas');
    JsBarcode(canvas, orderId, { format: 'CODE128' });
    return canvas.toDataURL('image/png');
};

// Register the locally downloaded Arabic font
Font.register({
    family: 'Noto Sans Arabic',
    src: '/assets/fonts/Noto_Sans_Arabic/NotoSansArabic-VariableFont_wdth,wght.ttf',  // Path to the font file you added
});



const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 10,
    },
    section: {
        marginBottom: 20,
        padding: 10,
        border: '1pt solid black',
    },
    barcode: {
        width: 225,
        height: 75,
        marginBottom: 10,
        marginLeft: "auto",
        marginRight: "auto",
    },
    text: {
        fontSize: 12,
        marginBottom: 5,
    },
    boldText: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
        fontFamily: "Noto Sans Arabic",
    },
    singleLine: {
        display: 'flex',
        flexDirection: 'row',
    },
    noteSection: {
        marginTop: 10,
    },
    header: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    barcodeContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    }
});

interface WaybillPrinterProps {
    orders: OrderType[];
    waybillsPerPage: number;
    orgId: string;
}

const WaybillPrinter = ({ orders, waybillsPerPage, orgId }: WaybillPrinterProps) => {
    return <Document>
        <Page key={'page'} style={styles.page}>
            {orders.map((order, index) => (
                <View key={order.order_id} style={styles.section} break={index > 0 && index % waybillsPerPage === 0}>
                    <View style={styles.row}>
                        <Text style={styles.boldText}>Delivery Organisation:</Text>
                        <Text style={styles.text}>{orgId}</Text>
                    </View>

                    {/* Barcode */}
                    <Image style={styles.barcode} src={generateBarcode(order.order_id)} />

                    <View style={styles.row}>
                        <Text style={styles.boldText}>Sender:</Text>
                        <Text style={styles.text}>{order.merchant.name} / {order.merchant.phone_number}</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.boldText}>Recepient:</Text>
                        <Text style={styles.text}>{order.customer?.name || 'N/A'} / {order.customer?.phone_number || 'N/A'}</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.boldText}>Address:</Text>
                        <Text style={styles.text}>{order.district} - {order.address}</Text>
                    </View>


                    <View style={styles.row}>
                        <Text style={styles.boldText}>Description:</Text>
                        <Text style={styles.text}>{order.description}</Text>
                    </View>

                    <View style={styles.row}>
                        <Text style={styles.boldText}>Notes:</Text>
                        <Text style={styles.text}>{order.public_note}</Text>
                    </View>
                </View>
            ))}
        </Page>
    </Document>
}


export default WaybillPrinter;
