import { Container, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import CreateBranchForm from '../branches/create-branch-form/create-branch-form';
import CreateLabelForm from '../labels/create-label-form/create-label-form';

function Label() {
    const { labelId } = useParams();
    return (
        <div>
            <Container>
                <Box>
                    <CreateLabelForm defaultLabelId={labelId} />
                </Box>
            </Container>
        </div>
    );
}

export default Label;
