
import { DefaultFilter } from './filter-types';

export const buildQueryParams = (filters: DefaultFilter[]): string => {
  let queryParams = "";

  filters.forEach((filter) => {
    queryParams += `&${filter.to_query()}`;
  });

  return queryParams
}