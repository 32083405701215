import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    LinearProgress,
    Chip,
} from '@mui/material';

import { LabelKeyWithValues } from '../../../../interfaces/delivery-company/labels';

interface LabelsListingTableProps {
    labelKeys: LabelKeyWithValues[];
    isLoading: boolean;
}

function LabelKeysListingTable({
    labelKeys,
    isLoading,
}: LabelsListingTableProps) {
    return (
        <TableContainer style={{ maxHeight: '80%' }}>
            {isLoading ? (
                <LinearProgress />
            ) : (
                <>
                    <Table stickyHeader style={{ textAlign: 'center' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <b>Key</b>
                                </TableCell>
                                <TableCell align="center">
                                    <b>Values</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                labelKeys.map((labelKey) => (
                                    <TableRow key={labelKey.id}>
                                        <TableCell align="center" style={{ cursor: 'pointer' }}>
                                            <a href={`/label/${labelKey.id}`}>
                                                <b>{labelKey.key}</b>
                                            </a>
                                        </TableCell>

                                        <TableCell align="center" style={{ cursor: 'pointer' }}>
                                            {labelKey.values.map((labelValue, index) => {
                                                if (index === 5) return "..."
                                                if (index > 5) return null
                                                return <Chip
                                                    key={labelValue.id}
                                                    color="secondary"
                                                    label={labelValue.value}
                                                    variant="filled"
                                                    style={{
                                                        margin: 3,
                                                    }}
                                                />
                                            }

                                            )}
                                        </TableCell>

                                    </TableRow>)
                                )
                            }

                        </TableBody>
                    </Table>
                </>
            )}
        </TableContainer>
    );
}

export default LabelKeysListingTable;
