import * as React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  LinearProgress,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import FeedIcon from '@mui/icons-material/Feed';
import InventoryIcon from '@mui/icons-material/Inventory';
import HistoryIcon from '@mui/icons-material/History';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
  cardStyle,
  cardHeaderStyle,
  cardTableStyle,
} from '../../common/styles/grid-card.style';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import {
  showDeliveryState,
  showPaymentState,
} from '../../common/styles/order-states.style';
import ConfirmAction from '../../common/confirm-action/confirm-action';
import { AuthContext } from '../../../contexts/user-context/user-context';
import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { PermissionsContext } from '../../../contexts/user-context/permissions-context';
import { UserType } from '../../../enums/users';

const parseEvents = (eventsAsJson: string): string[] => {
  const objs: string[] = JSON.parse(eventsAsJson);
  return objs;
};

function Order() {
  const { orderId } = useParams();

  const [orderData, setOrderData] = React.useState<OrderType>();
  const [confirmDeleteOrder, setConfirmDeleteOrder] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<AxiosResponse>();
  const [message, setMessage] = React.useState<string>('');


  const { user } = React.useContext(AuthContext);

  const canDeleteOrder = user?.type !== UserType.EMPLOYEE || user?.permissions?.includes("delete_order")
  const canUpdateOrder = user?.type !== UserType.EMPLOYEE || user?.permissions?.includes("change_order")

  console.log(user)

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  React.useEffect(() => {
    console.log('use effect');
    const getAndSetOrder = async () => {
      if (orderId !== undefined) {
        setIsLoading(true);
        try {
          const endpoint = `${API_ENDPOINT}/api/org/order/${orderId}/`;
          console.log(endpoint);
          const response = await axios.get<OrderType>(endpoint, config);
          console.log(response);
          setResponse(response);
          if (response.statusText == 'OK') {
            setOrderData(response.data);
          }
        } catch (e) {
          setOrderData(undefined);
          if (axios.isAxiosError(e)) {
            setMessage('Failed to load order data: ' + e.message);
          } else {
            setMessage('Failed to load order data');
          }
          console.log(e);
        }
        setIsLoading(false);
      }
    };
    getAndSetOrder();
  }, [orderId]);

  const navigate = useNavigate();

  const deleteOrder = async () => {
    if (orderData && orderData.id !== '') {
      setIsLoading(true);
      setResponse(undefined);
      try {
        const response = await axios.delete(
          `${API_ENDPOINT}/api/org/order/${orderData.id}/`,
          config
        );
        setResponse(response);
        if (response.status === 204) {
          setOrderData(undefined);
          setMessage('Order deleted successfully');
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          setResponse(e.response)
          setMessage('Failed to delete order: ' + e.response?.statusText);
        } else {
          setMessage('Failed to delete order');
        }
        console.log(e);
      }

      setIsLoading(false);
    }
  };

  return (
    <div className="content-container">
      <Box>
        <Typography
          variant="h3"
          component="h3"
          sx={{ textAlign: 'center', mt: 3, mb: 3 }}
        >
          Order Details
        </Typography>

        {isLoading && <LinearProgress />}

        {message !== '' && (

          <>
            <Alert
              severity={
                response && response.status >= 200 && response.status < 300
                  ? 'success'
                  : 'error'
              }
            >
              <>
                {message}

                {response &&
                  response.status >= 300 &&
                  Object.keys(response.data).map((key) => (
                    <>
                      <ul>
                        {
                          typeof response.data[key] === "string" ?
                            <li>{response.data[key]}</li> :
                            response.data[key].map((validationError: string) => (
                              <li key={key}>
                                {key}: {validationError}
                              </li>
                            ))}
                      </ul>
                    </>
                  ))}
              </>
            </Alert>

            <br />

          </>

        )}

        {!isLoading && orderData && (
          <>
            <Grid container spacing={2}>
              {/* General Information */}
              <Grid xs={12} md={4}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle}>
                    <InventoryIcon />
                    <div>
                      <b>General Information</b>
                    </div>
                  </div>

                  <table style={cardTableStyle}>
                    <tr>
                      <th>Order Id</th>
                      <td>{orderData.order_id}</td>
                    </tr>

                    <tr>
                      <th>Reference Id</th>
                      <td>{orderData.reference_id}</td>
                    </tr>
                    <tr>
                      <th>Customer</th>
                      <td>
                        <span
                          style={{ cursor: 'pointer', display: 'inline-block' }}
                          onClick={() =>
                            navigate(`/customer/${orderData.customer?.id}`)
                          }
                        >
                          <b>{orderData.customer?.name}</b>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Merchant</th>
                      <td>
                        {' '}
                        <span
                          style={{ cursor: 'pointer', display: 'inline-block' }}
                          onClick={() =>
                            navigate(`/merchant/${orderData.merchant.id}`)
                          }
                        >
                          <b>{orderData.merchant.name}</b>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{orderData.address}</td>
                    </tr>
                    <tr>
                      <th>Region</th>
                      <td>
                        {orderData.governorate}/{orderData.district}
                      </td>
                    </tr>
                  </table>
                </Card>
              </Grid>

              <Grid xs={12} md={4}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle}>
                    <FeedIcon />
                    <div>
                      <b>Extra Information</b>
                    </div>
                  </div>
                  <table style={cardTableStyle}>
                    <tr>
                      <th>Number of packages</th>
                      <td>{orderData.number_of_packages}</td>
                    </tr>

                    <tr>
                      <th>Branch</th>
                      <td>
                        <span
                          style={{ cursor: 'pointer', display: 'inline-block' }}
                          onClick={() =>
                            navigate(`/branch/${orderData.branch?.id}`)
                          }
                        >
                          <b>{orderData.branch?.name}</b>
                        </span>
                      </td>
                    </tr>
                    {orderData.description !== '' && (
                      <tr>
                        <th>Description</th>
                        <td>{orderData.description}</td>
                      </tr>
                    )}

                    {orderData.public_note !== '' && (
                      <tr>
                        <th>Public Note</th>
                        <td>{orderData.public_note}</td>
                      </tr>
                    )}

                    {orderData.private_note !== '' && (
                      <tr>
                        <th>Private Note</th>
                        <td>{orderData.private_note}</td>
                      </tr>
                    )}
                    <tr>
                      <th>Driver</th>
                      <td>
                        {orderData.driver ? (
                          <span
                            style={{
                              cursor: 'pointer',
                              display: 'inline-block',
                            }}
                            onClick={() =>
                              navigate(
                                `/${orderData.driver?.isGuest ? 'guest-' : ''}driver/${orderData.driver?.id}`
                              )
                            }
                          >
                            <b>{orderData.driver?.name}</b>
                          </span>
                        ) : (
                          'not specified'
                        )}
                      </td>
                    </tr>
                    {orderData.driver_note !== '' && (
                      <tr>
                        <th>Driver Note</th>
                        <td>{orderData.driver_note}</td>
                      </tr>
                    )}

                    <tr>
                      <th>Delivery Status</th>
                      <td>{showDeliveryState(orderData.delivery_state)}</td>
                    </tr>

                    <tr>
                      <th>Labels</th>
                      <td>{orderData.labels && orderData.labels.map(label =>
                        <Chip key={label.id} label={`${label.key}: ${label.value}`} sx={{ margin: 0.5 }} />)
                      }
                      </td>

                    </tr>
                  </table>
                </Card>
              </Grid>
              {/* Pricing Information */}
              <Grid xs={12} md={4}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle}>
                    <MonetizationOnIcon />
                    <div>
                      <b>Pricing</b>
                    </div>
                  </div>
                  <table style={cardTableStyle}>
                    <tr>
                      <th>Price ($)</th>
                      <td>{orderData.price_usd} $</td>
                    </tr>

                    <tr>
                      <th>Price (L.L)</th>
                      <td>{orderData.price_lbp} L.L</td>
                    </tr>
                    <tr>
                      <th>Delivery Fee ($)</th>
                      <td>{orderData.delivery_fee_usd} $</td>
                    </tr>
                    <tr>
                      <th>Delivery Fee (L.L)</th>
                      <td>{orderData.delivery_fee_lbp} L.L</td>
                    </tr>
                    <tr>
                      <th>Driver Commission ($)</th>
                      <td>{orderData.driver_commission_usd} $</td>
                    </tr>
                    <tr>
                      <th>Driver Commission (L.L)</th>
                      <td>{orderData.driver_commission_lbp} L.L</td>
                    </tr>
                    <tr>
                      <th>Payment Status</th>
                      <td>{showPaymentState(orderData.payment_state)}</td>
                    </tr>
                  </table>
                </Card>
              </Grid>
              {/* State History */}
              <Grid xs={12} md={6}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle}>
                    <HistoryIcon />
                    <div>
                      <b>State History</b>
                    </div>
                  </div>
                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Timestamp</b>
                          </TableCell>
                          <TableCell>
                            <b>Delivery State</b>
                          </TableCell>
                          <TableCell>
                            <b>Payment State</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {orderData.statestamps && (
                        <>
                          {orderData.statestamps.map((stateItem) => (
                            <TableRow
                              key={stateItem.timestamp}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {new Date(stateItem.timestamp).toLocaleString()}
                              </TableCell>
                              <TableCell>
                                {showDeliveryState(stateItem.delivery_state)}
                              </TableCell>
                              <TableCell>
                                {showPaymentState(stateItem.payment_state)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
              {/* Events */}
              <Grid xs={12} md={6}>
                <Card style={cardStyle}>
                  <div style={cardHeaderStyle}>
                    <TimelineIcon />
                    <div>
                      <b>Events</b>
                    </div>
                  </div>
                  <TableContainer sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Timestamp</b>
                          </TableCell>
                          <TableCell>
                            <b>Actor</b>
                          </TableCell>
                          <TableCell>
                            <b>Actions</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {orderData.events && (
                        <>
                          {orderData.events.map((event) => (
                            <TableRow
                              key={event.timestamp}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {new Date(event.timestamp).toLocaleString()}
                              </TableCell>
                              <TableCell scope="row">{event.actor}</TableCell>
                              <TableCell>
                                <ul>
                                  {parseEvents(event.description).map(
                                    (action) => (
                                      <li key={action}>{action}</li>
                                    )
                                  )}
                                </ul>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>
            </Grid>

            <br />

            <div style={{ display: 'flex', gap: 7 }}>
              <Button
                onClick={() => navigate(`/edit-order/${orderData.id}`)}
                disabled={!canUpdateOrder}
                variant="contained"
              >
                Edit Order
              </Button>

              <Button
                onClick={() => {
                  setConfirmDeleteOrder(true);
                }}
                disabled={!canDeleteOrder}
                variant="outlined"
                color="error"
              >
                Delete Order
              </Button>
            </div>
          </>
        )}
      </Box>
      <ConfirmAction
        message={'Are you sure you want to delete this order?'}
        action={deleteOrder}
        isOpen={confirmDeleteOrder}
        handleClose={() => setConfirmDeleteOrder(false)}
      >
        <div>Attention: This action can not be undone</div>
      </ConfirmAction>
    </div>
  );
}

export default Order;
