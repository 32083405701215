import React, { useState, ChangeEvent, KeyboardEvent, useRef } from 'react';
import { TextField, Chip, Box, InputAdornment } from '@mui/material';

interface ValueType {
    id: string;
    value: string;
}

interface MultipleValuesInputProps {
    values: ValueType[];
    setValues: React.Dispatch<React.SetStateAction<ValueType[]>>;
    disabled?: boolean;
    label: string;
}

const MultiTextField: React.FC<MultipleValuesInputProps> = ({ values, setValues, disabled, label }) => {
    const [inputValue, setInputValue] = useState<string>('');  // New value to add
    const [editingValue, setEditingValue] = useState<ValueType | null>(null);  // Value being edited
    const [editingIndex, setEditingIndex] = useState<number | null>(null);  // Index of value being edited

    const inputRef = useRef<HTMLInputElement | null>(null); // Reference to the input field to detect when it's blurred

    // Handle the input change for the text field
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setInputValue(e.target.value);
    };

    // Handle adding values (on Enter or comma)
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const trimmedValue = inputValue.trim();
            if (trimmedValue) {
                if (values.filter(item => item.value === trimmedValue).length === 0)
                    setValues((prevValues) => [...prevValues, { id: "", value: trimmedValue }]);
                setInputValue('');
            }
        }
    };

    // Handle deleting a value (chip removal)
    const handleDelete = (valueToDelete: ValueType) => (): void => {
        setValues((prevValues) => prevValues.filter((value) => value.id !== valueToDelete.id || value.value !== valueToDelete.value));
    };

    // Start editing a value (click on chip)
    const handleEdit = (index: number, value: ValueType) => (): void => {
        setEditingValue({ ...value });  // Set the value being edited with a copy of the selected value
        setEditingIndex(index);         // Set the index of the value being edited
    };

    // Save the edited value or remove the chip if value is empty
    const handleSaveEdit = (): void => {
        if (editingIndex !== null && editingValue !== null) {
            const trimmedValue = editingValue.value.trim();
            if (trimmedValue === '') {
                // If the value is empty after trimming, remove the chip
                setValues((prevValues) => prevValues.filter((_, index) => index !== editingIndex));
            } else {
                // Otherwise, update the value
                const updatedValues = [...values];
                updatedValues[editingIndex] = { ...editingValue, value: trimmedValue };
                setValues(updatedValues);
            }
            setEditingValue(null);
            setEditingIndex(null);
        }
    };

    // Handle pressing Enter or blur to save the edited value
    const handleEditKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            handleSaveEdit();
        }
    };

    // Handle blur event to save the edited value if the user clicks outside
    const handleBlur = (): void => {
        if (editingValue) {
            handleSaveEdit();
        }
    };

    // Handle input field blur to save new value if the user clicks outside
    const handleInputBlur = (): void => {
        const trimmedValue = inputValue.trim();
        if (trimmedValue) {
            if (values.filter(item => item.value === trimmedValue).length === 0)
                setValues((prevValues) => [...prevValues, { id: "", value: trimmedValue }]);
        }
        setInputValue('');  // Clear input value after blur
    };

    return (
        <Box sx={{ position: 'relative', padding: 2, border: '1px solid #e0e0e0', borderRadius: 2, marginTop: 2 }}>
            <Box sx={{ marginBottom: 1 }}>
                {/* Label for the input group */}
                <strong>{label}</strong>
            </Box>

            {/* Chips and Editable Values Container */}
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
                {values.map((value, index) => (
                    <Box key={index} sx={{ display: 'inline-block' }}>
                        {editingIndex === index ? (
                            <TextField
                                value={editingValue?.value ?? ''}  // Ensure this is set correctly
                                onChange={(e) => setEditingValue(
                                    { id: values[index].id, value: e.target.value }
                                )}
                                onKeyDown={handleEditKeyDown}
                                onBlur={handleBlur}
                                autoFocus
                                size="small"
                                variant="outlined"
                                sx={{ minWidth: 150, width: 'auto' }}
                            />
                        ) :
                            (
                                <Chip
                                    label={value.value}
                                    onDelete={handleDelete(value)}
                                    onClick={handleEdit(index, value)}
                                    disabled={disabled}
                                    sx={{
                                        backgroundColor: '#e0e0e0',
                                        margin: 0.5, // Adjust chip margin
                                    }}
                                />
                            )
                        }
                    </Box>
                ))}
            </Box>

            {/* Input Field for Adding New Values */}
            <TextField
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                label="Add Value"
                variant="outlined"
                fullWidth
                multiline
                disabled={disabled}
                inputRef={inputRef}
                onBlur={handleInputBlur}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {inputValue && (
                                <Chip label="Press Enter to add" size="small" color="secondary" />
                            )}
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};

export default MultiTextField;
